// INFO
// Componente che permette di visualizzare le informazioni relative al Meteo. Difatti viene utilizzato OpenWeather,
// recuperati i dati e qui gestito il messaggio di Output.

import React, { useEffect, useState } from "react";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

const Meteo = ({ lat, lon }) => {
  // api call to retrive meteo alert
  const [weatherData, setWeatherData] = useState(null);

  const apiKey = process.env.REACT_APP_OPEN_WEATHER_MAP;

  useEffect(() => {
    const fetchWeatherData = async () => {
      try {
        const response = await fetch(
          `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lon}&appid=${apiKey}`
        );
        if (response.ok) {
          const data = await response.json();
          setWeatherData(data);
        } else {
          console.error("Failed to fetch weather data");
        }
      } catch (error) {
        console.error("Error fetching weather data:", error);
      }
    };

    fetchWeatherData();
  }, [lat, lon]);

  /**
   * If current location is not avaible it can be necessary to pass
   * the start routing point and the and routing point
   */
  if (!weatherData) {
    return <div>Loading...</div>;
  }

  // Check weather conditions and set alerts
  let weatherAlert = null;
  if (weatherData.weather[0].main === "Rain") {
    weatherAlert = "Allerta pioggia!";
  } else if (weatherData.main.temp < 5) {
    weatherAlert = (
      <div className="alert">
        "Allerta freddo: le temperature sono al di sotoo dei 5 gradi"
      </div>
    );
  } else weatherAlert = "ok";

  // console.log(weatherData);

  return (
    // <div className="meteo">
    //   <div className="meteo-box"></div>
    // </div>
    <Stack sx={{ width: "100%" }} spacing={2}>
      {weatherAlert == "ok" ? (
        <Alert severity={"success"}>Meteo stabile</Alert>
      ) : (
        <Alert severity={"warning"}>{weatherAlert}</Alert>
      )}
    </Stack>
  );
};

export default Meteo;
