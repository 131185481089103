import "./App.css";
import Map from "./components/map/Map";
import RoutingForm from "./components/routing-form/RoutingForm";

function App() {
  return (
    <div className="App">
      <Map />
    </div>
  );
}

export default App;
