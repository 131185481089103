// INFO
// Componente che visulalizzo il form di routing presente nella pagina web

import React, { useState, useEffect } from "react";
import axios from "axios";
import Switch from "@mui/material/Switch";

import "./RoutingForm.css";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// icons
import InfoIcon from "@mui/icons-material/Info";
import CustomStepper from "../stepper/CustomStepper.js";
import Scooter from "../../assets/images/scooter.png";
import MicromobilityHouse from "../../assets/images/eco-house.png";

// data
import data from "./routingForm.json";
import Alert from "@mui/material/Alert";
import RouteDetail from "../route-detail/RouteDetail";

// i parametri di input sono tutte reference dalla classe padre "Map"
const RoutingForm = ({
  sendRoutingData,
  sendMultipleRoutingData,
  setSource,
  setDestinations,
  setFinalDestination,
  clearMap,
  location,
  handlePointOfInterest,
  micromobilityPoi,
  handlePoiMouseOver,
  handleClearTotal,
}) => {
  // findVeichle state
  const [findVeichle, setFindVeichle] = useState(false);
  const [veichleSelection, setVeichleSelection] = useState(false);

  // switch param state
  const [safeChecked, setSafeChecked] = React.useState(false); // parametro di sicurezza
  const [speedUpChecked, setSpeedUpChecked] = React.useState(false); // luoghi affollati
  const [greenChecked, setGreenChecked] = React.useState(false); // preferenza a strade con vegetazione
  const [airQualityChecked, setAirQualityChecked] = React.useState(false); // preferenza a strade con qualità dell'aria accettabile
  const [distanceChecked, setDistanceChecked] = React.useState(false); // preferenza a strade con la più breve distanza

  const handleSafeChange = (event) => {
    switch (event.target.value) {
      case "speedUp":
        setSpeedUpChecked(event.target.checked);
        break;
      case "safe_param":
        setSafeChecked(event.target.checked);
        break;
      case "green":
        setGreenChecked(event.target.checked);
        break;
      case "air_quality":
        setAirQualityChecked(event.target.checked);
        break;
      case "distance":
        setDistanceChecked(event.target.checked);
        break;
    }
  };

  // routing data
  const [routingData, setRoutingData] = useState([]);

  // stage position
  const [currentStagePosition, setCurrentStagePosition] = useState(0);

  // veichle
  const [veichle, setVeichle] = React.useState("");

  // search text
  const [searchText, setSearchText] = useState("");

  /**
   * ----------------------------------------------------------------------------------------------------------------------------
   */

  // if exist retrive current position address
  useEffect(() => {
    if (location) {
      // api call to transform lat, long into address
      const getFormattedAddress = async () => {
        const baseUrl = "https://nominatim.openstreetmap.org/reverse";
        const params = new URLSearchParams({
          lat: location.latitude,
          lon: location.longitude,
          format: "json", // Specify the response format as JSON
        });

        const url = `${baseUrl}?${params.toString()}`;

        try {
          const response = await axios.get(url);
          let tmp = [...stagesValue];
          const tmp_obj = {
            ...response.data,
            value: response.data.display_name,
          };

          tmp[0] = tmp_obj;
          setStagesValue(tmp);
          // console.log("Fromatted data: ", response.data); // The formatted address
        } catch (error) {
          console.error("Error fetching address:", error);
          return null;
        }
      };

      getFormattedAddress();
    }
  }, [location]);

  // stages default value
  const def = {
    class: "",
    display_name: "",
    icon: "",
    importance: "",
    lat: "",
    licence: "",
    lon: "",
    osm_id: "",
    osm_type: "",
    place_id: "",
    type: "",
    value: "",
    name: "destination",
    pos: -1,
  };

  // 1. define: stages variable
  const [stagesValue, setStagesValue] = useState([
    {
      class: "",
      display_name: "",
      icon: "",
      importance: "",
      lat: "",
      licence: "",
      lon: "",
      osm_id: "",
      osm_type: "",
      place_id: "",
      type: "",
      value: "",
      info: "source",
    },
    {
      class: "",
      display_name: "",
      icon: "",
      importance: "",
      lat: "",
      licence: "",
      lon: "",
      osm_id: "",
      osm_type: "",
      place_id: "",
      type: "",
      value: "",
      name: "destination",
    },
  ]);

  // 2. add stages function -> funzione di supporto per aggiungere un nuovo punto intermedio/destinazione
  const addStages = () => {
    let tmp_val = [...stagesValue, def];
    setStagesValue(tmp_val);
  };

  // 3. Remove stages function -> funzione di supporto per rimuovere un nuovo punto intermedio/destinazione
  const removeStages = () => {
    if (stagesValue.length <= 2) return;
    let tmp_val = stagesValue.slice(0, -1);
    setStagesValue(tmp_val);
  };

  /**
   * ----------------------------------------------------------------------------------------------------------------------------
   */
  // 1. define: list of address search api call result
  const [searchResult, setSearchResult] = useState([]);

  const handleSearch = async (searchTerm) => {
    try {
      const response = await axios.get(
        `https://nominatim.openstreetmap.org/search?q=${searchTerm}&format=json`
      );
      console.log(response);

      // console.log(
      //   "request " +
      //     `https://nominatim.openstreetmap.org/search?q=${searchTerm}&format=json`
      // );
      // console.log("nominatim response: ", response);
      setSearchResult(response.data);
      setSource(stagesValue[0]);
      setDestinations(stagesValue);
    } catch (error) {
      console.log(error);
    }
  };

  // 2. Api call: call the api to retrive the addresses
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleSearch(searchText);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchText]);

  // 3. handle result: add address to the "list" of the current destinations
  const handleSearchPoint = (el) => {
    /**
     * 1. add search text as value of the current source/destination
     * 2. clear the search maybe
     */

    // console.log(el);
    const tmp = { ...el, value: el.display_name };
    stagesValue[currentStagePosition] = { ...tmp };
    setSearchResult([]);
  };

  /**
   * Api calls
   * ----------------------------------------------------------------------------------------------------------------------------
   */
  // get node id from server
  const getNodeId = async (latitude, longitude) => {
    const url = `http://129.152.27.20:8085/graph/point?lat=${latitude}&lon=${longitude}`;
    // console.log("request: ", url);
    try {
      const response = await axios.get(url);
      // console.log("ok", response);
      return response.data.id; // Return the node ID
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const handleRouteSearch = async () => {
    if (stagesValue.length > 2) {
      await handleWayPointsRouteSearch();
      return;
    }
    try {
      clearMap();

      const sourceId = await getNodeId(stagesValue[0].lat, stagesValue[0].lon);
      const targetId = await getNodeId(
        stagesValue[stagesValue.length - 1].lat,
        stagesValue[stagesValue.length - 1].lon
      );

      if (!sourceId || !targetId) {
        console.log("No result");
        return;
      }

      // pass also the type of veichle
      let url = "";
      if (veichle == "" || veichle == "none")
        url = `http://129.152.27.20:8085/graph/route?sourceId=${sourceId}&destinationId=${targetId}`;
      else
        url = `http://129.152.27.20:8085/graph/customRoute?sourceId=${sourceId}&destinationId=${targetId}&profile=${veichle}&safeParam=${safeChecked}&distance_param=${distanceChecked}&air_pollution_param=${airQualityChecked}&green_param=${greenChecked}&bd_dijkstra_param=${speedUpChecked}`;

      console.log("url: ", url);

      const response = await axios.get(url);
      // console.log("route_2_path", response);
      setRoutingData(response?.data);
      sendRoutingData(response);
    } catch (error) {
      console.log(error);
    }
  };

  // Gestione dei punti intermedi
  const handleWayPointsRouteSearch = async () => {
    try {
      clearMap();

      let waypointsId = [];

      // recupera l'id del nodo della mappa più vicino al punto intermedio selezionato
      for (let i = 0; i < stagesValue.length; i++) {
        waypointsId.push(
          await getNodeId(stagesValue[i].lat, stagesValue[i].lon)
        );
      }

      let waypointTmp = { destinations: waypointsId };

      // console.log("waypoint route: ", waypointTmp);

      const url = `http://129.152.27.20:8085/graph/waypointsRoute`;

      const url_2 = `http://129.152.27.20:8085/graph/customWaypointsRoute`;
      let waypointTmp_2 = {
        profile: veichle,
        safeParam: safeChecked,
        distanceParam: distanceChecked,
        airPollutionParam: airQualityChecked,
        greenParam: greenChecked,
        destinations: waypointsId,
        bidirectionalDijkstraParam: speedUpChecked,
      };

      // api call
      // const response = await axios.post(url, waypointTmp);
      const response = await axios.post(url_2, waypointTmp_2);

      console.log("Waypoints_route", response);
      sendMultipleRoutingData(response);
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * ----------------------------------------------------------------------------------------------------------------------------
   */
  // topology api call
  const handleTopologyData = async () => {
    // await axios
    //   .get(
    //     "https://nominatim.openstreetmap.org/search?q=${searchTerm}&format=json"
    //   )
    //   .then((res) => {
    //     setSearchResult(res);
    //   })
    //   .catch((err) => console.log(err));
  };

  /**
   * ----------------------------------------------------------------------------------------------------------------------------
   */
  // topology only car api call
  const handleTopologyCarData = async () => {
    // await axios
    //   .get(
    //     "https://nominatim.openstreetmap.org/search?q=${searchTerm}&format=json"
    //   )
    //   .then((res) => {
    //     setSearchResult(res);
    //   })
    //   .catch((err) => console.log(err));
  };

  // topology only pedestrain api call
  const handleTopologyPedestrianData = async () => {
    // await axios
    //   .get(
    //     "https://nominatim.openstreetmap.org/search?q=${searchTerm}&format=json"
    //   )
    //   .then((res) => {
    //     setSearchResult(res);
    //   })
    //   .catch((err) => console.log(err));
  };

  // handle veichle type change
  const handleVeichleChange = (event) => {
    console.log(veichle);
    setVeichle(event.target.value);
  };

  /**
   * ----------------------------------------------------------------------------------------------------------------------------
   */
  // useEffect per gestire l'aggiunta e la rimozione delle nuove destinazioni o punti intermedi
  useEffect(() => {
    // console.log("stages");
    if (stagesValue.length === 1) {
      // set source
      setSource(stagesValue[0]);
    }

    if (stagesValue.length !== 1) {
      // set source
      setSource(stagesValue[0]);
      // set destination
      setFinalDestination(stagesValue[stagesValue.length - 1]);
      setDestinations(stagesValue);
      // console.log("setfinal", stagesValue);
      // console.log("final", stagesValue[stagesValue.length - 1]);
    }
  }, [
    stagesValue,
    stagesValue[0],
    stagesValue[1],
    stagesValue[stagesValue.length - 1],
  ]);

  // handle find veichle
  const quitVehicleSearch = () => {
    setFindVeichle(false);
  };

  const openVehicleSelection = () => {
    handlePointOfInterest();
  };

  const selectVeichle = (el) => {
    // select veichle from displayed

    console.log("element", el);

    // add to stages value
    addStages();

    // add veichle to the stagevALUE[1]
    let tmp = [...stagesValue];
    tmp[1] = {
      ...el,
      lat: el.latitude,
      lon: el.longitude,
      value: `${el.name}, ${el.address}, ${el.city}`,
    };
    setStagesValue(tmp);

    console.log("tmp", tmp);

    // quit the display

    quitVehicleSearch();
  };

  // il render è complesso perché gestisce la casistica di selezione del punto intermedio che si avvia con il click
  // su "Need to find a Veichle ?"
  return (
    <>
      {!findVeichle ? (
        <div className="search-form-box">
          <div className="flex-between routing-form-title">
            <h3 style={{ padding: 0, paddingBottom: "10px", margin: 0 }}>
              Plan your route
            </h3>
            <button onClick={handleClearTotal}>X</button>
          </div>
          <div className="search-form-veichle">
            <FormControl>
              {/* <FormLabel id="demo-row-radio-buttons-group-label">eichle type:</FormLabel> */}
              <h6 style={{ padding: 0, paddingBottom: "10px", margin: 0 }}>
                Veichle type:
              </h6>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                {/* <FormControlLabel
              value="female"
              control={<Radio onChange={handleVeichleChange} />}
              label="Female"
            />
            <FormControlLabel value="male" control={<Radio />} label="Male" />
            <FormControlLabel value="other" control={<Radio />} label="Other" /> */}

                {[
                  "pedestrian",
                  // "micromobililty",
                  "bike",
                  "e-bike",
                  "scooter",
                  // "none",
                ].map((el, index) => (
                  <FormControlLabel
                    value={el}
                    control={<Radio onChange={handleVeichleChange} />}
                    label={el}
                  />
                ))}
              </RadioGroup>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h6 style={{ padding: 0, paddingBottom: "10px", margin: 0 }}>
                  Safe param:
                </h6>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Switch
                    value={"safe_param"}
                    checked={safeChecked}
                    onChange={handleSafeChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                  <Tooltip title="If activated will prefer route based on the pre-built micromobility road">
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h6 style={{ padding: 0, paddingBottom: "10px", margin: 0 }}>
                  Speed Up:
                </h6>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Switch
                    value={"speedUp"}
                    checked={speedUpChecked}
                    onChange={handleSafeChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                  <Tooltip title="If activated will aaaaaavoid crowding places">
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h6 style={{ padding: 0, paddingBottom: "10px", margin: 0 }}>
                  Green route:
                </h6>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Switch
                    value={"green"}
                    checked={greenChecked}
                    onChange={handleSafeChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                  <Tooltip title="If activated will prefer green route">
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h6 style={{ padding: 0, paddingBottom: "10px", margin: 0 }}>
                  Air Quality:
                </h6>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Switch
                    value={"air_quality"}
                    checked={airQualityChecked}
                    onChange={handleSafeChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                  <Tooltip title="If activated will prefer route based on theair quality accettable value">
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h6 style={{ padding: 0, paddingBottom: "10px", margin: 0 }}>
                  Distance:
                </h6>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Switch
                    value={"distance"}
                    checked={distanceChecked}
                    onChange={handleSafeChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                  <Tooltip title="If activated will prefer route based on distance">
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            </FormControl>

            <div
              className=""
              onClick={() => {
                setFindVeichle(true);
                openVehicleSelection();
              }}
            >
              <h5 style={{ cursor: "pointer" }}>Need to find a veichle?</h5>

              {/* {stagesValue[0]?.lat != "" && (
                <Alert severity={"error"}>
                  Select a starting point to detect the nearest veichle
                </Alert>
              )} */}
            </div>

            <div className="veichle-list">
              {["Pedestrian", "e-bike", "scooter", "moped"].map(
                (el, pos) => {}
              )}
            </div>
          </div>
          <div className="search-form">
            {stagesValue &&
              stagesValue.length !== 0 &&
              stagesValue.map((el, pos) => (
                <input
                  key={pos + "b"}
                  type="text"
                  placeholder={"Enter destination " + pos}
                  value={el.value ? el.value : ""}
                  onFocus={() => setCurrentStagePosition(pos)}
                  onChange={(e) => {
                    // console.log("ok", searchText, "pos", pos, "arr", stagesValue);
                    stagesValue[pos].value = e.target.value;
                    setSearchText(e.target.value);
                  }}
                />
              ))}

            <button onClick={addStages}>Add destination</button>
            <button onClick={removeStages}>Remove last destination</button>
            <button onClick={handleRouteSearch}>Search</button>
          </div>
          <div className="results">
            {searchResult &&
              searchResult.length !== 0 &&
              searchResult.map((el, pos) => (
                <div
                  className="result-item"
                  onClick={() => handleSearchPoint(el)}
                  key={pos + "a"}
                >
                  {" "}
                  {el.display_name}{" "}
                </div>
              ))}
          </div>

          <div className="routing-info">
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Route Info</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <RouteDetail routeData={routingData} />
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      ) : (
        <div className="search-form-box">
          {/* <CustomStepper
            steps={data?.veichleSelectionSteps}
            quit={quitVehicleSearch}
          /> */}
          <div className="displayNearestVeichle">
            <div onClick={quitVehicleSearch}>Quit</div>

            <div className="poi-box">
              {micromobilityPoi &&
                micromobilityPoi.map((el, index) => (
                  <div
                    className="poi-item"
                    onClick={() => selectVeichle(el, index)}
                    onMouseOver={() => {
                      handlePoiMouseOver(index, "active");
                    }}
                    onMouseLeave={() => {
                      handlePoiMouseOver(index, "none");
                    }}
                  >
                    <div className="poi-item-img">
                      <img
                        src={
                          el.category == "Micromobility"
                            ? MicromobilityHouse
                            : Scooter
                        }
                      />
                    </div>
                    <div>
                      <div className="poi-item-address">
                        <h4>{el.address}</h4>
                      </div>

                      <div className="poi-item-category">
                        <h4>{el.category}</h4>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RoutingForm;
