import React from "react";

const MapLegend = ({ items }) => {
  return (
    <div className="legend">
      {items.map((item) => (
        <div key={item.category} className="legend-item">
          <span
            className="legend-color"
            style={{ backgroundColor: item.color }}
          ></span>
          <span className="legend-text">{item.label}</span>
        </div>
      ))}
    </div>
  );
};

export default MapLegend;
