import React, { useEffect } from "react";
import { useMap } from "react-leaflet";

const TopologyLayerChanges = ({ footway, elevation }) => {
  const map = useMap();

  useEffect(() => {
    const handleOverlayChange = (e) => {
      let { name } = e.target;
      switch (name) {
        case "Footway":
          footway();
          break;
        case "Elevation":
          elevation();
          break;
        default:
          // Handle other overlay changes
          break;
      }
    };

    map.on("overlayadd", handleOverlayChange);

    return () => {
      map.off("overlayadd", handleOverlayChange);
    };
  }, []);

  return null;
};

export default TopologyLayerChanges;
