// import React, { useEffect, useRef } from "react";
// import { useMap } from "react-leaflet";
// import L from "leaflet";

// const DisplayRoutePath = ({ data }) => {
//   const map = useMap();
//   const parentLayerRef = useRef(null);
//   const childLayersRef = useRef([]);

//   console.log("routepath: ", data);

//   useEffect(() => {
//     const parentLayer = L.featureGroup().addTo(map);
//     parentLayerRef.current = parentLayer;

//     if (data && Array.isArray(data)) {
//       // Clear previous child layers
//       childLayersRef.current.forEach((layer) => parentLayer.removeLayer(layer));
//       childLayersRef.current = [];

//       // Create and add new child layers to the parent layer
//       data.forEach((geoJsonData) => {
//         const childLayer = L.geoJSON(geoJsonData.geom);
//         parentLayer.addLayer(childLayer);
//         childLayersRef.current.push(childLayer);
//       });
//     }

//     // Cleanup function
//     return () => {
//       if (parentLayerRef.current) {
//         parentLayerRef.current.clearLayers();
//         map.removeLayer(parentLayerRef.current);
//       }
//     };
//   }, [data, map]);

//   return null;
// };

// export default DisplayRoutePath;

import React, { useEffect, useRef } from "react";
import { useMap } from "react-leaflet";
import L from "leaflet";
import { randomColor } from "../Utils";

const DisplayRoutePath = ({ data, color, index }) => {
  const map = useMap();
  const parentLayerRef = useRef(null);
  const childLayersRef = useRef([]);

  // console.log("color: ", color);

  // console.log("routepath: ", data);

  useEffect(() => {
    const parentLayer = L.featureGroup().addTo(map);
    parentLayerRef.current = parentLayer;

    if (data && Array.isArray(data)) {
      // Clear previous child layers
      childLayersRef.current.forEach((layer) => parentLayer.removeLayer(layer));
      childLayersRef.current = [];

      // Create and add new child layers to the parent layer
      data.forEach((geoJsonData) => {
        // let ranColor = randomColor();
        // Create a style object with the random color
        const style = {
          color: color || "#0000", // Use color directly, not { color }   // TODO: UNCOMMENT
          // color: `${ranColor}`,
          weight: 5, // Adjust line weight as needed
        };

        // console.log("random", ranColor, geoJsonData.id);

        // Create the child layer with the specified style
        const childLayer = L.geoJSON(geoJsonData.geom, { style: style });

        // childLayer.bindPopup(`ID: ${geoJsonData.id}, color: ${ranColor}`);
        childLayer.bindPopup(`ID: ${geoJsonData.id}, color: ${color}`);

        parentLayer.addLayer(childLayer);
        childLayersRef.current.push(childLayer);
      });
    }

    // Cleanup function
    return () => {
      if (parentLayerRef.current) {
        parentLayerRef.current.clearLayers();
        map.removeLayer(parentLayerRef.current);
      }
    };
  }, [data, map]);

  return null;
};

export default DisplayRoutePath;
