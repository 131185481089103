import React, { useEffect } from "react";
import { useMap } from "react-leaflet";
import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";
import { icon } from "../map/Constants";

const LeafletgeoSearch = () => {
  const map = useMap();
  useEffect(() => {
    const provider = new OpenStreetMapProvider();

    const searchControl = new GeoSearchControl({
      provider,
      marker: {
        icon,
      },
    });

    map.addControl(searchControl);

    function searchEventHandler(result) {
      map.flyTo([result.location.y, result.location.x], 14, {
        animate: true,
      });
    }

    map.on("geosearch/showlocation", searchEventHandler);

    return () => map.removeControl(searchControl);
  }, []);

  return null;
};

export default LeafletgeoSearch;
