// INFO
// Componente che permette di visualizzare le informazioni relative al percorso elaborato.
// Questo componente non funziona benissimo e bisogna sistemare le indicazioni fornite.
// Inoltre bisogna modificare il dato recuperato dal DB relativo al tempo di percorrenza.

import React, { useEffect, useState } from "react";
import "./RouteDetail.css";

const RouteDetail = ({ routeData, walkingSpeed = 19.8 }) => {
  const [directions, setDirections] = useState([]);
  const [totalDistance, setTotalDistance] = useState(0);
  const [totalTime, setTotalTime] = useState(0);

  const calculateDirection = (start, end) => {
    const [startLng, startLat] = start;
    const [endLng, endLat] = end;
    let direction = "";

    const deltaLat = Math.log(
      Math.tan(endLat / 2 + Math.PI / 4) / Math.tan(startLat / 2 + Math.PI / 4)
    );
    const deltaLng = Math.abs(endLng - startLng) % 180;
    const deltaLn = endLng - startLng;
    const deltaLt = endLat - startLat;

    const angle = (Math.atan(deltaLt / deltaLn) * 180) / Math.PI;

    // console.log(angle);
    // console.log(deltaLt < 0 ? "SUD " : "NORD ", deltaLn < 0 ? "OVEST" : "EST");

    return direction;
  };

  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Radius of the earth in km
    const dLat = ((lat2 - lat1) * Math.PI) / 180;
    const dLon = ((lon2 - lon1) * Math.PI) / 180;
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((lat1 * Math.PI) / 180) *
        Math.cos((lat2 * Math.PI) / 180) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // Distance in km
    return distance * 1000; // Convert to meters
  };

  useEffect(() => {
    if (routeData && routeData.length > 1) {
      // Check if routeData exists and has at least two elements
      console.log("Route Data: ", routeData);
      const calculatedDirections = [];
      let totalDistance = 0;
      let totalTime = 0;

      for (let i = 0; i < routeData.length - 1; i++) {
        const currentSegment = routeData[i];
        const nextSegment = routeData[i + 1];

        if (currentSegment.geom && nextSegment.geom) {
          // Check if geom property exists in both segments
          const start = currentSegment.geom.coordinates[0];
          const startEnd =
            currentSegment.geom.coordinates[
              currentSegment.geom.coordinates.length - 1
            ];

          if (currentSegment.geom.type === "MultiLineString") {
            for (let j = 0; j < currentSegment.geom.length - 1; j++) {
              // currentSegment = geom.coordinates
              const multiCurrentSegment = routeData[i].geom.coordinates[j];
              const multiNextSegment = routeData[i].geom.coordinates[j + 1];

              if (multiCurrentSegment.geom && multiNextSegment.geom) {
                let multiStart = multiCurrentSegment.geom.coordinates[0][0];
                let multiStartEnd =
                  multiCurrentSegment.geom.coordinates[0][
                    multiCurrentSegment.geom.coordinates.length - 1
                  ];
                const multiEnd = multiNextSegment.geom.coordinates[0];

                const direction = calculateDirection(
                  multiStart,
                  Array.isArray(multiStartEnd)
                    ? multiStartEnd[multiStartEnd.length - 1]
                    : multiStartEnd
                );

                const distance = calculateDistance(
                  multiStart[1], // Latitude of the starting point
                  multiStart[0], // Longitude of the starting point
                  startEnd[1], // Latitude of the ending point
                  startEnd[0] // Longitude of the ending point
                );

                console.log("Distance: ", distance);

                totalDistance += distance;

                // Calculate time based on walking speed (speed in km/h)
                const time = distance / walkingSpeed;
                totalTime += time;

                // Determine cardinal direction
                let cardinalDirection = "";

                if (direction === "North") {
                  cardinalDirection = "Nord";
                } else if (direction === "South") {
                  cardinalDirection = "Sud";
                } else if (direction === "East") {
                  cardinalDirection = "Est";
                } else if (direction === "West") {
                  cardinalDirection = "Ovest";
                }

                // Create the road instruction based on the direction and distance
                const instruction = `Vai verso ${direction} per ${distance.toFixed(
                  2
                )} metri`;

                calculatedDirections.push(instruction);
              }
            }
          } else {
            if (
              nextSegment.geom &&
              Array.isArray(nextSegment.geom.coordinates)
            ) {
              const end = nextSegment.geom.coordinates[0];
              const direction = calculateDirection(
                start,
                Array.isArray(startEnd[0])
                  ? startEnd[startEnd.length - 1]
                  : startEnd
              );

              // Rest of the code for the else block
              const distance = calculateDistance(
                start[1], // Latitude of the starting point
                start[0], // Longitude of the starting point
                startEnd[1], // Latitude of the ending point
                startEnd[0] // Longitude of the ending point
              );

              totalDistance += distance;

              // Calculate time based on walking speed (speed in km/h)
              const speedMetersPerSecond = (walkingSpeed * 1000) / 3600;
              const time = distance / (walkingSpeed / 3.6);
              totalTime += time;

              console.log("Distance_2: ", distance);
              console.log("Time_2: ", time);

              // Determine cardinal direction
              let cardinalDirection = "";

              if (direction === "North") {
                cardinalDirection = "Nord";
              } else if (direction === "South") {
                cardinalDirection = "Sud";
              } else if (direction === "East") {
                cardinalDirection = "Est";
              } else if (direction === "West") {
                cardinalDirection = "Ovest";
              }

              // Create the road instruction based on the direction and distance
              const instruction = `Vai verso ${direction} per ${distance.toFixed(
                2
              )} metri`;

              calculatedDirections.push(instruction);
            } else {
              // Handle the case where nextSegment.geom.coordinates is not an array
              console.error(
                "Invalid data structure for nextSegment.geom.coordinates"
              );
            }
          }
        }
      }

      setDirections(calculatedDirections);
      setTotalDistance(totalDistance);
      setTotalTime(totalTime);
    }
  }, [routeData, walkingSpeed]);

  return (
    <div className="route_detail">
      <div className="route_detail_box">
        <h1>Indicazioni Stradali</h1>
        <ul>
          {directions.map((instruction, index) => (
            <li key={index}>{instruction}</li>
          ))}
        </ul>
        <p>Distanza totale percorsa: {totalDistance.toFixed(2)} m</p>
        <p>Tempo medio di percorso: {(totalTime / 60).toFixed(2)} minuti</p>
      </div>
    </div>
  );
};

export default RouteDetail;
