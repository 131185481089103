import React, { useEffect, useRef } from "react";
import { useMap } from "react-leaflet";
import L from "leaflet";

const DisplayMultipleRoutePath = ({ data }) => {
  const map = useMap();
  const parentLayerRef = useRef(null);
  const childLayersRef = useRef([]);

  useEffect(() => {
    const parentLayer = L.featureGroup().addTo(map);
    parentLayerRef.current = parentLayer;

    if (data && Array.isArray(data)) {
      // Clear previous child layers
      childLayersRef.current.forEach((layer) => parentLayer.removeLayer(layer));
      childLayersRef.current = [];

      // Create and add new child layers to the parent layer
      data.forEach((el) => {
        el.forEach((geoJsonData) => {
          const childLayer = L.geoJSON(geoJsonData.geom);
          parentLayer.addLayer(childLayer);
          childLayersRef.current.push(childLayer);
        });
      });
    }

    // Cleanup function
    return () => {
      if (parentLayerRef.current) {
        parentLayerRef.current.clearLayers();
        map.removeLayer(parentLayerRef.current);
      }
    };
  }, [data, map]);

  return null;
};

export default DisplayMultipleRoutePath;
