import React, { useEffect, useRef } from "react";
import { useMap } from "react-leaflet";
import L from "leaflet";
import { icon } from "../map/Constants";

const DisplayAllRoutePoints = ({ destinations }) => {
  const map = useMap();
  const parentLayerRef = useRef(null);
  const childLayersRef = useRef([]);

  useEffect(() => {
    const parentLayer = L.featureGroup().addTo(map);
    parentLayerRef.current = parentLayer;

    if (!destinations) return;

    if (destinations && Array.isArray(destinations)) {
      // Clear previous child layers
      childLayersRef.current.forEach((layer) => parentLayer.removeLayer(layer));
      childLayersRef.current = [];

      // Create and add new child layers to the parent layer
      destinations.forEach((point) => {
        const lat = parseFloat(point.lat);
        const lng = parseFloat(point.lon);
        if (isNaN(lat) || isNaN(lng)) {
          console.error("Invalid source coordinates");
          return;
        }
        const childLayer = L.marker([lat, lng], { icon: icon }).addTo(map);
        childLayer.bindPopup(
          `Latitude: ${lat?.toFixed(6)}, Longitude: ${lng?.toFixed(6)}`
        );
        parentLayer.addLayer(childLayer);
        childLayersRef.current.push(childLayer);
      });
    }

    // Cleanup function
    return () => {
      if (parentLayerRef.current) {
        parentLayerRef.current.clearLayers();
        map.removeLayer(parentLayerRef.current);
      }
    };
  }, [...destinations, map]); // Include a shallow clone of the destinations array

  return null;
};

export default DisplayAllRoutePoints;
