import React, { useEffect, useRef } from "react";
import { useMap } from "react-leaflet";
import L from "leaflet";
import { icon } from "../map/Constants";
import axios from "axios";

const MapOnClickMarker = () => {
  const map = useMap();
  const markerRef = useRef(null);

  // get node id from server
  const getNodeId = async (latitude, longitude) => {
    const url = `http://129.152.27.20:8085/graph/point?lat=${latitude}&lon=${longitude}`;
    // console.log("request: ", url);
    try {
      const response = await axios.get(url);
      // console.log("ok", response);
      return response.data.id; // Return the node ID
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  useEffect(() => {
    async function onOverlayChange(e) {
      if (markerRef.current) {
        map.removeLayer(markerRef.current);
      }

      const { lat, lng } = e.latlng;

      // add axios db call to retrive the nearest point on the map and console log it
      const nodeId = await getNodeId(lat, lng);

      const marker = L.marker([lat, lng], { icon: icon }).addTo(map);
      marker.bindPopup(
        `Id:${nodeId}, Latitude: ${lat.toFixed(6)}, Longitude: ${lng.toFixed(
          6
        )}`
      );
      markerRef.current = marker;
    }

    map.on("click", onOverlayChange);

    return () => {
      map.off("click", onOverlayChange);

      if (markerRef.current) {
        map.removeLayer(markerRef.current);
      }
    };
  }, [map]);

  return null;
};

export default MapOnClickMarker;
