// import React, { useEffect, useRef } from "react";
// import { useMap } from "react-leaflet";
// import L from "leaflet";
// import { scooterHome, scooterIcon } from "../map/Constants";

// const DisplayPOI = ({ poi, setLegend }) => {
//   const map = useMap();
//   const parentLayerRef = useRef(null);
//   const childLayersRef = useRef([]);

//   // Set legend
//   // Define the legend items with categories and colors

//   useEffect(() => {
//     const parentLayer = L.featureGroup().addTo(map);
//     parentLayerRef.current = parentLayer;

//     if (poi && Array.isArray(poi)) {
//       // Clear previous child layers
//       childLayersRef.current.forEach((layer) => parentLayer.removeLayer(layer));
//       childLayersRef.current = [];

//       // Create and add new child layers to the parent layer
//       poi.forEach((point) => {
//         const lat = parseFloat(point.latitude);
//         const lng = parseFloat(point.longitude);
//         if (isNaN(lat) || isNaN(lng)) {
//           console.error("Invalid source coordinates");
//           return;
//         }

//         // todo: add custom icons
//         let childLayer;

//         switch (point?.category) {
//           case "Micromobility":
//             childLayer = L.marker([lat, lng], { icon: scooterHome }).addTo(map);
//             break;
//           case "Scooter":
//             childLayer = L.marker([lat, lng], { icon: scooterIcon }).addTo(map);
//             break;
//         }

//         childLayer.bindPopup(
//           `Latitude: ${lat?.toFixed(6)}, Longitude: ${lng?.toFixed(6)}`
//         );
//         parentLayer.addLayer(childLayer);
//         childLayersRef.current.push(childLayer);
//       });
//     }

//     // Cleanup function
//     return () => {
//       if (parentLayerRef.current) {
//         parentLayerRef.current.clearLayers();
//         map.removeLayer(parentLayerRef.current);
//       }
//     };
//   }, [poi ? [...poi] : null, map, setLegend]); // Include a shallow clone of the poi array or null

//   // Set the legend outside of useEffect with an empty dependency array
//   useEffect(() => {
//     const legendItems = [
//       { category: "Micromobility", color: "green", label: "Micromobility" },
//       { category: "Scooter", color: "blue", label: "Scooter" },
//       // Add more legend items as needed
//     ];
//     setLegend(legendItems);
//   }, []); // Empty dependency array to trigger once on mount

//   return null;
// };

// export default DisplayPOI;

// ------------------------------------------------------------------------------------------

// import React, { useEffect, useRef } from "react";
// import { useMap } from "react-leaflet";
// import L from "leaflet";
// import { scooterHome, scooterIcon } from "../map/Constants";

// import Scooter from "../../assets/images/scooter.png";
// import MicromobilityHouse from "../../assets/images/eco-house.png";

// const DisplayPOI = ({ poi, setLegend, poiIconAnimation }) => {
//   const map = useMap();
//   const parentLayerRef = useRef(null);
//   const childLayersRef = useRef([]);

//   useEffect(() => {
//     const parentLayer = L.featureGroup().addTo(map);
//     parentLayerRef.current = parentLayer;

//     // Clear previous child layers
//     childLayersRef.current.forEach((layer) => parentLayer.removeLayer(layer));
//     childLayersRef.current = [];

//     if (poi && Array.isArray(poi)) {
//       poi.forEach((point, index) => {
//         const lat = parseFloat(point.latitude);
//         const lng = parseFloat(point.longitude);
//         if (isNaN(lat) || isNaN(lng)) {
//           console.error("Invalid source coordinates");
//           return;
//         }

//         let childLayer;

//         // Convert React components to strings
//         const scooterHtml = `<img src="${Scooter}" width="32" height="32" />`;
//         const micromobilityHtml = `<img src="${MicromobilityHouse}" width="32" height="32" />`;

//         // Inside the loop where you create and add markers
//         switch (point?.category) {
//           case "Micromobility":
//             childLayer = L.marker([lat, lng], {
//               icon: L.divIcon({
//                 className: "icon-wrapper",
//                 html: micromobilityHtml,
//               }),
//             }).addTo(map);
//             break;
//           case "Scooter":
//             childLayer = L.marker([lat, lng], {
//               icon: L.divIcon({
//                 className: "icon-wrapper",
//                 html: scooterHtml,
//               }),
//             }).addTo(map);
//             break;
//         }

//         childLayer.bindPopup(
//           `Latitude: ${lat?.toFixed(6)}, Longitude: ${lng?.toFixed(6)}`
//         );
//         parentLayer.addLayer(childLayer);
//         childLayersRef.current.push(childLayer);

//         // Apply animation to marker if needed
//         if (poiIconAnimation && poiIconAnimation.index === index) {
//           const currentAnimationState = poiIconAnimation.state;

//           if (currentAnimationState === "active") {
//             childLayer._icon.classList.add("animate");
//           } else {
//             childLayer._icon.classList.remove("animate");
//           }
//         }
//       });
//     }

//     // Cleanup function
//     return () => {
//       if (parentLayerRef.current) {
//         parentLayerRef.current.clearLayers();
//         map.removeLayer(parentLayerRef.current);
//       }
//     };
//   }, [poi, map, setLegend, poiIconAnimation]);

//   // Set the legend outside of useEffect with an empty dependency array
//   useEffect(() => {
//     const legendItems = [
//       { category: "Micromobility", color: "green", label: "Micromobility" },
//       { category: "Scooter", color: "blue", label: "Scooter" },
//       // Add more legend items as needed
//     ];
//     setLegend(legendItems);
//   }, []);

//   return null;
// };

// export default DisplayPOI;

import React, { useEffect, useRef } from "react";
import { useMap } from "react-leaflet";
import L from "leaflet";
import { scooterHome, scooterIcon } from "../map/Constants";

import Scooter from "../../assets/images/scooter.png";
import MicromobilityHouse from "../../assets/images/eco-house.png";
import Parking from "../../assets/images/parking-icon.png";

const DisplayPOI = ({ poi, setLegend, poiIconAnimation }) => {
  const map = useMap();
  const parentLayerRef = useRef(null);
  const childLayersRef = useRef([]);

  // console.log("poi, poi");

  useEffect(() => {
    const parentLayer = L.featureGroup().addTo(map);
    parentLayerRef.current = parentLayer;

    // Clear previous child layers
    childLayersRef.current.forEach((layer) => parentLayer.removeLayer(layer));
    childLayersRef.current = [];

    if (poi && Array.isArray(poi)) {
      poi.forEach((point, index) => {
        const lat = parseFloat(point.latitude);
        const lng = parseFloat(point.longitude);
        if (isNaN(lat) || isNaN(lng)) {
          console.error("Invalid source coordinates");
          return;
        }

        let childLayer;

        // Convert React components to strings
        const scooterHtml = `<div class="icon-inner"><img src="${Scooter}" width="32" height="32" /></div>`;
        const micromobilityHtml = `<div class="icon-inner"><img src="${MicromobilityHouse}" width="32" height="32" /></div>`;
        const parkingHtml = `<div class="icon-inner"><img src="${Parking}" width="32" height="32" /></div>`;

        // Inside the loop where you create and add markers
        switch (point?.category) {
          case "Micromobility":
            childLayer = L.marker([lat, lng], {
              icon: L.divIcon({
                className: "icon-wrapper",
                html: micromobilityHtml,
              }),
            }).addTo(map);
            break;
          case "Scooter":
            childLayer = L.marker([lat, lng], {
              icon: L.divIcon({
                className: "icon-wrapper",
                html: scooterHtml,
              }),
            }).addTo(map);
            break;
          case "Parking":
            childLayer = L.marker([lat, lng], {
              icon: L.divIcon({
                className: "icon-wrapper",
                html: parkingHtml,
              }),
            }).addTo(map);
            break;
        }

        childLayer.bindPopup(
          `Latitude: ${lat?.toFixed(6)}, Longitude: ${lng?.toFixed(6)}`
        );
        parentLayer.addLayer(childLayer);
        childLayersRef.current.push(childLayer);

        // Apply animation on mouseover
        childLayer.on("mouseover", () => {
          childLayer._icon
            .querySelector(".icon-inner")
            .classList.add("animate");
        });

        // Remove animation on mouseout
        childLayer.on("mouseout", () => {
          childLayer._icon
            .querySelector(".icon-inner")
            .classList.remove("animate");
        });

        // Apply animation to marker if needed
        if (poiIconAnimation && poiIconAnimation.index === index) {
          const currentAnimationState = poiIconAnimation.state;

          if (currentAnimationState === "active") {
            childLayer._icon
              .querySelector(".icon-inner")
              .classList.add("animate");
          } else {
            childLayer._icon
              .querySelector(".icon-inner")
              .classList.remove("animate");
          }
        }
      });
    }

    // Cleanup function
    return () => {
      if (parentLayerRef.current) {
        parentLayerRef.current.clearLayers();
        map.removeLayer(parentLayerRef.current);
      }
    };
  }, [poi, map, setLegend, poiIconAnimation]);

  // Set the legend outside of useEffect with an empty dependency array
  useEffect(() => {
    const legendItems = [
      { category: "Micromobility", color: "green", label: "Micromobility" },
      { category: "Scooter", color: "blue", label: "Scooter" },
      // Add more legend items as needed
    ];
    setLegend(legendItems);
  }, []);

  return null;
};

export default DisplayPOI;
