import React, { useEffect, useRef, useState } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMap,
  Polyline,
  GeoJSON,
  LayersControl,
  LayerGroup,
  Circle,
  FeatureGroup,
  Rectangle,
} from "react-leaflet";
import axios from "axios";

import "./Map.css";
import L, { Icon, map, marker } from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-geosearch/dist/geosearch.css";

import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";
import { icon, scooterIcon, scooterHome } from "./Constants";
import RoutingForm from "../routing-form/RoutingForm";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

// icons
import HubIcon from "@mui/icons-material/Hub";
import LeafletgeoSearch from "../map-component/LeafletgeoSearch";
import TopologyLayerChanges from "../map-component/TopologyLayerChanges";
import MapOnClickMarker from "../map-component/MapOnClickMarker";
import DisplayAllRoutePoints from "../map-component/DisplayAllRoutePoints";
import DisplayPOI from "../map-component/DisplayPOI";
import DisplayRoutePath from "../map-component/DisplayRoutePath";
import DisplayMultipleRoutePath from "../map-component/DisplayMultipleRoutePath";
import CenterMap from "../map-component/CenterMap";
import Meteo from "../meteo/Meteo";
import CurrentLocation from "../current-location/CurrentLocation";
import RouteDetail from "../route-detail/RouteDetail";
import MapLegend from "../legend/MapLegend";
import CustomStepper from "../stepper/CustomStepper.js";
import { randomColor } from "../Utils";
const { BaseLayer } = LayersControl;

export default function Map() {
  // current location
  const [location, setLocation] = useState(null);

  // map legend
  // ex.  category: "Micromobility", color: "green", label: "Micromobility" }
  const [legend, setLegend] = useState([]);

  // map TileLayer variables
  //   const mapRef = useMap();

  const [center, setCenter] = useState([45.5188, 9.214]);

  // MapTopology
  const [footwayTopology, setFootwayTopology] = useState({});
  const [elevationTopology, setElevationTopology] = useState({});
  const [trafficTopology, setTrafficTopology] = useState({});
  const [pavementQuality, setPavementQuality] = useState({});
  const [airQuality, setAirQuality] = useState({});
  const [green, setGreen] = useState({});

  /** routing
  1. define: path result
   */
  const [source, setSource] = useState();
  const [destinations, setDestinations] = useState();
  const [finalDestination, setFinalDestination] = useState();
  // const [pathObj, setPathObj] = useState({});
  const [pathObj, setPathObj] = useState([]);

  // multiple path routing
  const [multiplePathObj, setMultiplePathObj] = useState({});

  // map POI
  const [poi, setPoi] = useState([]);

  // map topology line hovered status
  const [hoveredFeature, setHoveredFeature] = useState(null);

  /**
   * 2. clear result before each search
   * 3. add markers to teh destinations and display the path to the map via lineString ecc ecc
   */

  /**
   * Routing form pasing map data
   */

  /**
   * Map topology
   * Display the default Bicocca map topology. Maybe change the background of the current map
   */
  const handleTopology = (data) => {
    // Do something
    // console.log("data_basic_topology", data);
  };

  /**
   * Solo walk topology
   * Display the default Bicocca solo wal map topology. Maybe change the background of the current map
   */
  const handleFootwayTopology = async () => {
    // Do something
    await axios
      .get("http://129.152.27.20:8085/graph/footwayTopology")
      .then((res) => {
        setFootwayTopology(res);
      })
      .catch((err) => console.log(err));
  };

  // topology elevation street api call
  const handleTopologyElevationStreetData = async () => {
    await axios
      .get("http://129.152.27.20:8085/graph/mapTopologyElevation")
      .then((res) => {
        setElevationTopology(res);
      })
      .catch((err) => console.log(err));
  };

  // topology of traffic condition
  const handleDisplayTraffic = async () => {
    await axios
      .get("http://129.152.27.20:8085/graph/mapTopologyTraffic")
      .then((res) => {
        setTrafficTopology(res);
      })
      .catch((err) => console.log(err));
  };

  // pavement quality
  const handlePavementQuality = async () => {
    await axios
      .get("http://129.152.27.20:8085/graph/mapPavementQualityTopology")
      .then((res) => {
        // console.log(res);
        setPavementQuality(res);
      })
      .catch((err) => console.log(err));
  };

  // pavement quality
  const handleAirQuality = async () => {
    await axios
      .get("http://129.152.27.20:8085/graph/airQualityTopology")
      .then((res) => {
        // console.log(res);
        setAirQuality(res);
      })
      .catch((err) => console.log(err));
  };

  // green
  const handleGreen = async () => {
    await axios
      .get("http://129.152.27.20:8085/graph/greenTopology")
      .then((res) => {
        console.log("Green count: ", res.data.length);
        setGreen(res);
      })
      .catch((err) => console.log(err));
  };

  /**
   * Handle Rout component data
   */

  // two entity path
  const handleRouting = (data) => {
    // Do something
    // Fly to firstCoordinate position
    clearMap();

    changeCenterPosition(data.data[0].geom.coordinates[0]);

    // set source
    // setSource(data.data[0]);
    // set destination
    // setFinalDestination(data.data[data.data.length - 1]);
    setMultiplePathObj({});
    setPathObj((prevPathObj) => [...prevPathObj, data]);
  };

  // multiple path (last version, think to use just this)
  const handleMultipleRouting = (data) => {
    // Do something
    // Fly to firstCoordinate position
    clearMap();

    changeCenterPosition(data.data[0][0].geom.coordinates[0]);

    // set source
    // setSource(data.data[0]);
    // set destination
    // setFinalDestination(data.data[data.data.length - 1]);
    setPathObj([]);
    setMultiplePathObj(data);
  };

  // handle POI retrive

  // retrive the nearest POI
  const handleNearestPointOfInterest = async (latitude, longitude) => {
    const url = `http://129.152.27.20:8085/graph/graphNearestPoi?currentLat=${latitude}&currentLng=${longitude}&category=Micromobility`;
    try {
      const response = await axios.get(url);
      // console.log("poi", response);
      return response.data.id; // Return the node ID
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  // retrive all the poi of teh map
  const handlePointOfInterest = async () => {
    const url = `http://129.152.27.20:8085/graph/graphPoi`;
    try {
      const response = await axios.get(url);
      // console.log("poi_res", response);
      response.data = response.data.filter(
        (el) => !(el.category === "Parking")
      );
      setPoi(response.data); // Return the node ID
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  useEffect(() => {
    // console.log("source", source);
    // console.log("destination", finalDestination);
  }, [source, finalDestination]);

  // path useEffect
  useEffect(() => {
    // on path change set the current view to the path
    // if (source) map.setView([source.lat, source.lon], map.getZoom());
  }, [pathObj]);

  /**
   * get topology
   *
   * 1. get all edges
   * 2. get all nodes
   */

  /**
   * TODO: Change the centering map position based on the source node
   *
   * Improoved versione: canged based on the source and destination on the box boundary
   *
   * @param {*} arr
   */
  const changeCenterPosition = (arr) => {
    let tmp = [...arr];

    setCenter(tmp.reverse());
  };

  // clear map
  const clearMap = () => {
    setPoi([]);
    setPathObj([]);
    setFootwayTopology({});
    setElevationTopology({});
    setTrafficTopology({});
    setPavementQuality({});
    setAirQuality({});
    setGreen({});
    setLegend([]);
  };

  const handleClearTotal = () => {
    setPoi([]);
    setPathObj([]);
    setFootwayTopology({});
    setElevationTopology({});
    setTrafficTopology({});
    setPavementQuality({});
    setGreen({});
    setAirQuality({});
    setLegend([]);
  };

  // source destination markers
  // useEffect(() => {
  //   if (source) {
  //     const marker = L.marker([source.lat, source.lng], { icon: icon }).addTo(
  //       map
  //     );
  //     marker.bindPopup(
  //       `Latitude: ${source.lat.toFixed(6)}, Longitude: ${sourcelng.toFixed(6)}`
  //     );
  //   }
  //   if (finalDestination) {
  //     const marker = L.marker([finalDestination.lat, finalDestination.lng], {
  //       icon: icon,
  //     }).addTo(map);
  //     marker.bindPopup(
  //       `Latitude: ${finalDestination.lat.toFixed(
  //         6
  //       )}, Longitude: ${finalDestination.lng.toFixed(6)}`
  //     );
  //   }
  // }, [source, finalDestination]);

  // const getColorScale = (slope) => {
  //   const minSlope = 0; // Define the minimum slope percentage
  //   const maxSlope = 100; // Define the maximum slope percentage

  //   // Define the color scale range
  //   const colorRange = ["green", "yellow", "red"];

  //   // Calculate the slope range
  //   const slopeRange = maxSlope - minSlope;

  //   // Calculate the position within the slope range
  //   const position = (slope - minSlope) / slopeRange;

  //   // Calculate the index of the color within the color range
  //   const colorIndex = Math.round(position * (colorRange.length - 1));

  //   // Return the color based on the color index
  //   return colorRange[colorIndex];
  // };

  const getColorScale = (slopePercentage) => {
    const colorScale = [
      "#005C00",
      "#006E00",
      "#007F00",
      "#009000",
      "#00A100",
      "#00B200",
      "#00C300",
      "#00D400",
      "#00E600",
      "#00F700",
      "#FFF500",
      "#FFEB00",
      "#FFE100",
      "#FFD600",
      "#FFCC00",
      "#FFC100",
      "#FFB600",
      "#FFAB00",
      "#FFA000",
      "#FF9500",
      "#FF8A00",
      "#FF7F00",
      "#FF7400",
      "#FF6900",
      "#FF5E00",
      "#FF5300",
      "#FF4800",
      "#FF3D00",
      "#FF3200",
      "#FF2700",
      "#FF1C00",
      "#FF1200",
      "#FF0700",
      "#FF0700",
      "#FF0700",
      "#FF0700",
      "#FF0700",
      "#FF0700",
      "#FF0700",
      "#FF0700",
      "#FF0700",
      "#FF0700",
      "#FF0700",
      "#FF0700",
      "#FF0700",
      "#FF0700",
      "#FF0700",
      "#FF0700",
      "#FF0700",
      "#FF0700",
      "#FF0700",
      "#FF0700",
      "#FF0700",
      "#FF0700",
      "#FF0700",
      "#FF0700",
      "#FF0700",
      "#FF0700",
      "#FF0700",
      "#FF0700",
      "#FF0700",
      "#FF0700",
      "#FF0700",
      "#FF0700",
    ];

    const colorIndex = Math.round(Math.abs(slopePercentage));
    return colorScale[colorIndex % 50];
  };

  const getTrafficColor = (trafficCondition) => {
    switch (trafficCondition) {
      case "incident":
        return "#FF0000"; // red
      case "congested":
        return "#ff7514"; // orange
      case "light":
        return "#e5be01 "; // lightgray
      case "moderate":
        return "#ffa420"; // orange
      case "good":
        return "#308446"; // lightgreen
      default:
        return "#000000"; // default color (black)
    }
  };

  const getPavementQualityColor = (streetTypology) => {
    switch (streetTypology) {
      case "gravel":
        return "brown"; // saddlebrown
      case "sett":
        return "#D2691E"; // chocolate
      case "unpaved":
        return "orange"; // peru
      case "fine_gravel":
        return "gray"; // gray
      case "compacted":
        return "lightgray"; // darkgray
      case "paving_stones":
        return "lightblue"; // lightsteelblue
      case null:
        return "red"; // lightgray
      case "concrete":
        return "green"; // whitesmoke
      case "paved":
        return "bec2cb"; // silver
      case "asphalt":
        return "#828282"; // black
      case "ground":
        return "#8B0000"; // darkred
      default:
        return "#000000"; // default color (black)
    }
  };

  const getAirQualityColor = (pmValue) => {
    if (pmValue < 20) {
      return "green"; // Good
    } else if (pmValue < 80) {
      return "yellow"; // Fair
    } else if (pmValue < 250) {
      return "orange"; // Moderate
    } else if (pmValue < 350) {
      return "red"; // Poor
    } else {
      return "purple"; // Very Poor
    }
  };

  const [value, setValue] = React.useState("none");
  const handleTopologyChange = (event) => {
    // clear Map on each topology change
    clearMap();
    setValue(event.target.value);
    if (event.target.value === "elevation") {
      clearMap();
      handleTopologyElevationStreetData();
    } else if (event.target.value === "footway") {
      clearMap();
      handleFootwayTopology();
    } else if (event.target.value === "poi") {
      clearMap();
      handlePointOfInterest();
    } else if (event.target.value === "traffic") {
      handleDisplayTraffic();
    } else if (event.target.value === "pavementQuality") {
      handlePavementQuality();
    } else if (event.target.value === "airQuality") {
      handleAirQuality();
    } else if (event.target.value === "green") {
      handleGreen();
    } else {
      clearMap();
    }
  };

  // poi icon animation on mouseOver
  const [poiIconAnimation, setPoiIconAnimation] = useState({});
  const handlePoiMouseOver = (index, state) => {
    // remove the class to all the markers

    // add the animation class to the selected items

    setPoiIconAnimation({ index: index, state: state });
  };

  return (
    <div className="map-container">
      <div className="topology-form">
        <div className="topology-icon">
          <HubIcon />
        </div>
        <FormControl className="topology-form-content">
          <FormLabel id="demo-radio-buttons-group-label">Topografy</FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="female"
            name="radio-buttons-group"
            value={value}
            onChange={handleTopologyChange}
          >
            <FormControlLabel
              value="none"
              control={<Radio size="small" />}
              label="None"
            />
            <FormControlLabel
              value="footway"
              control={<Radio size="small" />}
              label="Footway"
            />
            <FormControlLabel
              value="elevation"
              control={<Radio size="small" />}
              label="Elevation"
            />
            <FormControlLabel
              value="poi"
              control={<Radio size="small" />}
              label="Poi"
            />
            <FormControlLabel
              value="traffic"
              control={<Radio size="small" />}
              label="Traffic"
            />
            <FormControlLabel
              value="pavementQuality"
              control={<Radio size="small" />}
              label="PavementQuality"
            />
            <FormControlLabel
              value="green"
              control={<Radio size="small" />}
              label="Green"
            />
            <FormControlLabel
              value="airQuality"
              control={<Radio size="small" />}
              label="AirQuality"
            />
          </RadioGroup>
        </FormControl>
      </div>
      <div className="routing-form-map">
        <RoutingForm
          sendTopologyData={handleTopology}
          sendRoutingData={handleRouting}
          sendMultipleRoutingData={handleMultipleRouting}
          setSource={setSource}
          setDestinations={setDestinations}
          setFinalDestination={setFinalDestination}
          clearMap={clearMap}
          location={location}
          handlePointOfInterest={handlePointOfInterest}
          micromobilityPoi={poi}
          handlePoiMouseOver={handlePoiMouseOver}
          handleClearTotal={handleClearTotal}
        />
      </div>
      <Meteo
        lat={location?.latitude || source?.latitude}
        lon={location?.longitude || source?.longitude}
      />

      <MapContainer
        center={center}
        zoom={15}
        scrollWheelZoom={true}
        // ref={mapRef}
        style={{ width: "100%", height: "calc(100vh - 4rem)" }}
        className="map"
      >
        <LayersControl>
          <BaseLayer checked name="Light">
            <TileLayer
              url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            />
          </BaseLayer>
          <BaseLayer name="Gray">
            <TileLayer
              className="gray-map"
              url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            />
          </BaseLayer>
          <BaseLayer name="Dark">
            <TileLayer
              url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png"
              attribution='&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> &copy; <a href="http://cartodb.com/attributions">CartoDB</a>'
            />
          </BaseLayer>
        </LayersControl>

        <LeafletgeoSearch />

        {/* <TailLayerChaneges center={center} size={1000} /> */}

        <TopologyLayerChanges
          elevation={handleTopologyElevationStreetData}
          footway={handleFootwayTopology}
        />

        <CenterMap center={center} />

        <MapOnClickMarker />

        {/* <DisplayRoutePoints source={source} destination={finalDestination} /> */}

        {destinations && <DisplayAllRoutePoints destinations={destinations} />}

        {poi && (
          <DisplayPOI
            poi={poi}
            setLegend={setLegend}
            poiIconAnimation={poiIconAnimation}
          />
        )}

        {/* {pathObj.data && <GeoJSON data={pathObj.data} />} */}

        {/* {pathObj.data?.map((feature) => (
          // <GeoJSON key={feature.id} data={feature.geom} />

          <CustomMapEffect data={feature.geom} />
        ))} */}

        {/* {pathObj && (
          <DisplayRoutePath data={pathObj.data} color={randomColor()} />
        )} */}
        {pathObj &&
          pathObj.map((el, ind) => (
            <DisplayRoutePath
              data={el.data}
              index={ind}
              color={randomColor()}
            />
          ))}

        {multiplePathObj && (
          <DisplayMultipleRoutePath data={multiplePathObj.data} />
        )}

        {/* {multiplePathObj?.map((el) =>
          el.map((el_in) => <CustomMapEffect data={el_in.geom} />)
        )} */}

        {footwayTopology?.data?.map((feature) => (
          <GeoJSON key={feature.id} data={feature.geom} />
        ))}

        {elevationTopology?.data?.map((feature) => {
          const slope =
            (feature.source_elevation - feature.target_elevation) /
            (feature.distance * 1000);

          const style = {
            color: getColorScale(slope),
          };

          return (
            <GeoJSON key={feature.id} data={feature.geom} style={() => style} />
          );
        })}

        {trafficTopology?.data?.map((feature) => {
          // console.log(trafficTopology);
          const style = {
            color: getTrafficColor(feature.trafficCondition),
          };

          return (
            <GeoJSON
              key={feature.id}
              data={feature.geom}
              style={() => style}
              eventHandlers={{
                click: () => console.log("id", feature.id),
                mouseover: () => setHoveredFeature(feature),
                mouseout: () => setHoveredFeature(null),
              }}
            >
              {/* {hoveredFeature && hoveredFeature.id === feature.id && ( */}
              <Popup>
                <div>road_id: {feature.id}</div>
              </Popup>
              {/* )} */}
            </GeoJSON>
          );
        })}

        {pavementQuality?.data?.map((feature, id) => {
          const style = {
            color: getPavementQualityColor(feature.surface),
          };

          return (
            <GeoJSON
              key={feature.id}
              data={feature.geom}
              style={() => style}
              // Gestisce il click sugli archi
              // eventHandlers={{
              //   click: () => console.log("id", feature.id),
              //   mouseover: () => setHoveredFeature(feature),
              //   mouseout: () => setHoveredFeature(null),
              // }}
            >
              {hoveredFeature && hoveredFeature.id === feature.id && (
                <Popup>
                  <div>{feature.surface}</div>
                </Popup>
              )}
            </GeoJSON>
          );
        })}

        {airQuality?.data?.map((feature, id) => {
          const style_pm2 = {
            color: getAirQualityColor(feature.pm2),
          };

          const style_pm10 = {
            color: getAirQualityColor(feature.pm10),
          };

          return (
            <React.Fragment key={id}>
              <GeoJSON
                key={`pm2-${feature.id}`} // Use a unique key for pm2
                data={feature.geom}
                style={() => style_pm2}
                // Gestisce il click sugli archi
                // eventHandlers={{
                //   click: () => console.log("id", feature.id),
                //   mouseover: () => setHoveredFeature(feature),
                //   mouseout: () => setHoveredFeature(null),
                // }}
              >
                {hoveredFeature && hoveredFeature.id === feature.id && (
                  <Popup>
                    <div>pm2: {feature.pm2}</div>
                  </Popup>
                )}
              </GeoJSON>

              <GeoJSON
                key={`pm10-${feature.id}`} // Use a unique key for pm10
                data={feature.geom}
                style={() => style_pm10}
                // Gestisce il click sugli archi
                // eventHandlers={{
                //   click: () => console.log("id", feature.id),
                //   mouseover: () => setHoveredFeature(feature),
                //   mouseout: () => setHoveredFeature(null),
                // }}
              >
                {hoveredFeature && hoveredFeature.id === feature.id && (
                  <Popup>
                    <div>pm10: {feature.pm10}</div>
                  </Popup>
                )}
              </GeoJSON>
            </React.Fragment>
          );
        })}

        {green?.data?.map((feature) => (
          <GeoJSON
            key={feature.id}
            data={feature.geom}
            style={{ weight: 2, color: "blue" }}
          />
        ))}

        <div className="current_location_map">
          <CurrentLocation setLocation={setLocation} />
        </div>

        {legend && <MapLegend items={legend} />}
      </MapContainer>

      {/* Info sul percoso della mappa (da implementare, non funziona molto bene) */}
      {/* <RouteDetail routeData={pathObj?.data} /> */}

      {/* Leggenda della mappa, non necessaria */}
      {/* {legend && <MapLegend items={legend} />} */}

      {/* 
      componente che avrebbe permesso di scegliere il veicolo in un modo più carino utilizzando una sorta di "stepper",
      ovvero la presenza di divese schermate dove l'utente avrebbe effettuato le diverse scelte 

      es. schermata 1: visuailzzazione del veicolo -> schermaata 2: scelta -> schermata 3: visualizzazione dei punti di rilascio etc. */}
      {/* <CustomStepper currentLocation={location} /> */}
    </div>
  );
}
